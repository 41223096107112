import React from 'react';
import { navigate } from 'gatsby';
import { Container, Content } from '../../components/home/homeStyledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/Footer';
import LoginBlock from '../../components/MyAccount/LoginBlock';
import { trackEvent } from '../../services/analytics';
import callApi from '../../services/api';
import Layout from '../../layouts/index';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.login = this.login.bind(this);
  }

  login() {
    const { email, password } = this.state;
    this.setState({ isLoginLoading: true, errorLogin: false });
    callApi('login', 'post', { email, password })
      .then(() => {
        this.setState({ isLoginLoading: false });
        navigate('/mes-commandes');
      })
      .catch(() => {
        trackEvent('erreur', 'echec-login');
        this.setState({ errorLogin: true, isLoginLoading: false });
      });
  }

  render() {
    return (
      <Layout>
        <Container>
          <Content>
            <MenuBar />
            <LoginBlock
              isLoading={this.state.isLoginLoading}
              error={this.state.errorLogin}
              inputChange={(field, value) => this.setState({ [field]: value })}
              login={this.login}
            />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default Login;
